import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import SubHeader from '../components/SubHeaderProfile';
// import * as ROUTES from '../src/constants/routes';
import Digital from '../components/DigitalView';

class DigitalPage extends React.Component {
  jiko() {
    window.location.href="#jiko";
  }
  haikei() {
  window.location.href="#haikeiDigital";
  }

  render() {
    return (
      <Fragment>
      <SubHeader />
      <title>デジタル運営チーム 國學院大學久我山高等学校 バスケットボール部</title>
  <div className="digital-background">

  <div className="profile-container">
  <div className="profile-area-main" id="jiko">
  <h2 className="content-title-white-profile">自己紹介</h2>
  <div className="profile-maintext-digital">
  <Digital />
  </div>
<div className="arrowWrap" onClick={this.haikei}>
  <div className="arrow1">
    <span></span>
  </div>
  <div className="arrow2">
    <span></span>
  </div>
</div>
</div>

<div className="profile-area-main" id="haikeiDigital">
<h2 className="content-title-white-profile">デジタル運営チームとは</h2>
<div>
<div className="profile-maintext-digital">
　社会ではデジタル化が進む昨今で、久我山高校バスケ部もデジタル化の波に乗る為に、本ホームページ運営を主とする「デジタル運営チーム」を2020年7月に発足。酒井監督の「みんなから応援されるチームを作る」という理念を体現する為、OBや保護者など久我山高校バスケ部の各関係者に情報の発信を行う。
</div>
<div className="profile-maintext-digital">
「学生主体で考えて行動する事」が、デジタル運営チームの基本理念であり、OBへのインタビュー設計からインタビューの実施、関係者への情報発信内容を考え、お知らせとして告知するなど、社会で直接活きるスキルや経験を、現役の社会人の指導の下、実践を通して身につける事ができる。
</div>
<div className="profile-maintext-digital">
※現在、久我山高校バスケ部の「デジタル運営チーム」は発足したばかりの為、一緒に久我山高校バスケ部を盛り上げてくれる新しいメンバーを募集しております。
</div>
</div>
</div>

</div>
  </div>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <DigitalPage />
  </Layout>
);
